<div class="input-container h100p" [ngClass]="{'labeled': label}" [class.teams]="teamsApp">
    <div *ngIf="isShowLabel && label" class="label-container">
        <span class="input-label" [class.required]="alwaysShowRequiredIndicator || required">{{translate ? (label | translate) : label }}</span>
        <app-tooltip *ngIf="tooltip" [options]="tooltip" [translate]="translate"></app-tooltip>
    </div>

    <ng-container *ngIf="type === 'file'">
        <ng-container *ngTemplateOutlet="fileInput"></ng-container>
    </ng-container>

    <ng-container *ngIf="type !== 'file'">
        <div class="text-box-container" [ngSwitch]="type" [ngClass]="{'cPointer': readonly || type === 'file', 'cPointer pen': isDisabled}">
            <app-icon class="input-icon prepend" [ngClass]="{'light': lightTheme}" *ngIf="prependIcon" [faIcon]="prependIcon"></app-icon>
            <div *ngIf="prefix && showPrefix" class="input-prefix">{{ prefix }}</div>
            <input *ngSwitchCase="'phone'"
                [id]="inputId"
                type="text" 
                matInput
                ng2TelInput
                class="intl-phone-number"
                [ngClass]="{'cPointer': readonly}"
                [ng2TelInputOptions]="{ phoneValidation: true, utilsScript: '/assets/js/intl-tel-input-utils.js' }"
                [required]="required" [readonly]="readonly || isDisabled"
                [placeholder]="placeholder ? (translate ? (placeholder | translate) : placeholder) : '' "
                [disabled]="isDisabled"
                [ngModel]="value"
                [ngClass]="{
                    'readonly': readonly,
                    'disabled' : isDisabled,
                    'clickable': isClickable,
                    'dark': dark,
                    'light': light,
                    'icon-padding': prependIcon,
                    'pL10': !prependIcon,
                    'pR30': appendIcon,
                    'pR10': !appendIcon
                }"
                (ngModelChange)="setValue($event)"
                (blur)="blur()"
                (focus)="focus()"
                (countryChange)="onCountryChange($event)"
                [autocomplete]="hideAutoComplete ? isIeOrEdge ? 'disabled' : 'off' : 'new-password'"
                #input
                [style.width]="width ? width : '100%'"
                [style.borderBottomColor]="focused ? bottomBorderColor ?? 'var(--input-bottom-border-color)' : 'transparent'"
                [style.backgroundColor]="backgroundColor ?? null"
            >

            <input *ngSwitchDefault
                [id]="inputId"
                [placeholder]="placeholder ? (translate ? (placeholder | translate) : placeholder) : '' "
                [disabled]="isDisabled || isObfuscated"
                [readonly]="readonly || isDisabled || isObfuscated"
                [ngClass]="{
                    'cPointer': readonly,
                    'icon-padding': prependIcon,
                    'pL10': !prependIcon,
                    'pR30': appendIcon,
                    'pR10': !appendIcon,
                    'light': lightTheme,
                    'showing-drop-down': showDropDown && filteredAutocompleteOptions?.length,
                    'disabled' : isDisabled,
                    'clickable': isClickable,
                    'dark': dark,
                    'light': light,
                    'space-on-right': appendIcon
                }"
                [type]="type"
                [ngModel]="valueDisplay"
                (ngModelChange)="setValue($event)"
                (blur)="blur()"
                (focus)="focus()"
                [autocomplete]="hideAutoComplete ? isIeOrEdge ? 'disabled' : 'off' : 'new-password'"
                (keydown)="onKeyPress($event)"
                [maxLength]="!!maxLength ? maxLength : DEFAULT_MAX_LENGTH"
                #input
                [style.padding-left]="prependIcon ? '35px' : '10px'"
                [style.padding-right]="appendIcon ? '35px' : '10px'"
                [style.width]="width ? width : '100%'"
                [style.borderBottomColor]="focused ? bottomBorderColor ?? 'var(--input-bottom-border-color)' : 'transparent'"
                [style.backgroundColor]="backgroundColor ?? null"
            >
            
            <app-icon
                *ngIf="appendIcon"
                class="input-icon append"
                [ngClass]="[appendIconClass ? appendIconClass : '']"
                [class.light]="lightTheme"
                [class.has-actions]="appendActions?.length"
                [faIcon]="appendIcon"
            ></app-icon>
        </div>
        <div *ngIf="appendActions?.length" class="append-actions-container">
            <app-icon class="cPointer" [matMenuTriggerFor]="appendActionsMenu" [icon]="{'faIcon': 'fas fa-chevron-down', 'fontSize': '14px'}" (click)="$event.stopPropagation()"></app-icon>
        </div>
    </ng-container>

    <div *ngIf="filteredAutocompleteOptions?.length" class="drop-down"
        [ngClass]="{ 'light': lightTheme, 'hidden': !showDropDown, 'disabled' : isDisabled }" appClickOutside
        (clickOutside)="toggleDropDown(false)">
        <div class="autocomplete-options">
            <button class="option" [ngClass]="{'light': lightTheme}" *ngFor="let option of filteredAutocompleteOptions"
                (click)="selectAutocomplete(option); $event.stopPropagation()">
                <div class="option-label">
                    {{ option.value }}
                </div>
            </button>
        </div>
    </div>
    <div *ngIf="showErrors" class="error-container" [ngClass]="[errorClass ? errorClass : '']">
        <mat-error *ngIf="formControl?.hasError('emailOrPhone')">{{ 'formValidation.emailOrPhone' | translate }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('email')">{{ 'formValidation.email' | translate:{ label: (label | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('maxlength')">{{ 'formValidation.maxlength' | translate:{ label: (label | translate), length: formControl.errors.maxlength.requiredLength } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('minlength')">{{ 'formValidation.minlength' | translate:{ label: (label | translate), length: formControl.errors.minlength.requiredLength } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('number')">{{ 'formValidation.number' | translate:{ label: (label | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('phoneNumber') && formControl?.touched">{{ 'formValidation.phoneNumber' | translate:{ label: (label | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('required') && formControl?.touched">{{ 'formValidation.required' | translate:{ label: (label | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('uniqueInArray')">{{ 'formValidation.uniqueInArray' | translate:{ label: (label | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('uniqueInLookUpMap')">{{ 'formValidation.uniqueInLookUpMap' | translate:{ label: (label | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('max')">{{ 'formValidation.max' | translate:{ label: (label | translate), val: formControl.errors.max.max } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('min')">{{ 'formValidation.min' | translate:{ label: (label | translate), val: formControl.errors.min.min } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('lessThan')">{{ 'formValidation.lessThan' | translate:{ label: (label | translate), val: formControl.errors.lessThan.max } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('greaterThan')">{{ 'formValidation.greaterThan' | translate:{ label: (label | translate), val: formControl.errors.greaterThan.min } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('greaterThanOrEqual')">{{ 'formValidation.greaterThanOrEqual' | translate:{ label: (label | translate), val: formControl.errors.greaterThanOrEqual.min } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('lessThanOrEqual')">{{ 'formValidation.lessThanOrEqual' | translate:{ label: (label | translate), val: formControl.errors.lessThanOrEqual.max } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('companyExists')">{{ 'formValidation.companyExists' | translate }}</mat-error>
    </div>
</div>

<!-- isolated to so icons can be incapsulated with file input clicks-->
<ng-template #fileInput>
    <div class="text-box-container h100p" class="cPointer">
        <div class="file-input-container">
            <app-icon class="input-icon prepend" [ngClass]="{'light': lightTheme}" *ngIf="prependIcon" [faIcon]="prependIcon"></app-icon>
    
            <input
                class="cPointer"
                [id]="inputId"
                [placeholder]="placeholder ? (translate ? (placeholder | translate) : placeholder) : '' "
                [disabled]="isDisabled"
                [ngClass]="{
                    'icon-padding': icon,
                    'pL10': !icon,
                    'pR30': appendIcon,
                    'pR10': !appendIcon,
                    'light': lightTheme,
                    'showing-drop-down': showDropDown && filteredAutocompleteOptions?.length,
                    'disabled' : isDisabled,
                    'clickable': isClickable,
                    'dark': dark
                }"
                [ngModel]="value"
                (blur)="blur()"
                (focus)="focus()"
                autocomplete="off"
                #input
                [style.padding-left]="prependIcon ? '35px' : 0"
                [style.padding-right]="appendIcon ? '35px' : 0"
                [style.width]="width ? width : '100%'"
            >
    
            <input type="file"
                name="file"
                class="file-input"
                [id]="inputId + '-file'"
                accept="{{acceptableFileTypes}}"
                (change)="setFileValue($event?.target?.files?.[0])"
                [style.padding-left]="prependIcon ? '35px' : 0"
                [style.padding-right]="appendIcon ? '35px' : 0"
                [style.width]="width ? width : '100%'"
            >
    
            <app-icon class="input-icon append" [ngClass]="[appendIconClass ? appendIconClass : '', lightTheme ? 'light' : '']" *ngIf="appendIcon" [faIcon]="appendIcon"></app-icon>
            <label class="upload-label" [for]="inputId + '-file'"></label>
        </div>
    </div>
</ng-template>

<mat-menu id="append-actions-menu" class="append-actions-menu" #appendActionsMenu="matMenu" yPosition="below" xPosition="after">
    <button mat-menu-item *ngFor="let item of appendActions" class="append-actions-menu-item flexRow jcC aiC" (click)="item.action()" [disabled]="item.disabled">
        {{ item.textKey | translate }}
    </button>
</mat-menu>
