import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import intlTelInput, { Iti, SomeOptions } from 'intl-tel-input';

const defaultUtilScript = '/assets/js/intl-tel-input-utils.js';

/**
 * npm ng2-tel-input is no longer supported.
 * Fortunately it's a simple library, so we've reimplemented it here.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[ng2TelInput]',
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class Ng2TelInput implements OnInit {
    @Input() ng2TelInputOptions: SomeOptions = {};
    @Output() hasError = new EventEmitter<boolean>();
    @Output() ng2TelOutput = new EventEmitter<string>();
    @Output() countryChange = new EventEmitter<ReturnType<Iti['getSelectedCountryData']>>();
    @Output() intlTelInputObject = new EventEmitter<Iti>();

    ngTelInput: Iti;

    constructor(
        private readonly el: ElementRef,
        @Inject(PLATFORM_ID) private readonly platformId: string,
    ) {}

    ngOnInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.ng2TelInputOptions = {
                countrySearch: false,
                initialCountry: 'us',
                preferredCountries: ['us', 'gb'],
                ...this.ng2TelInputOptions,
                utilsScript: this.getUtilsScript(this.ng2TelInputOptions),
            };
            this.ngTelInput = intlTelInput(this.el.nativeElement, {
                ...this.ng2TelInputOptions,
            });

            this.el.nativeElement.addEventListener('countrychange', () => {
                this.countryChange.emit(this.ngTelInput.getSelectedCountryData());
            });

            this.intlTelInputObject.emit(this.ngTelInput);
        }
    }

    @HostListener('blur') onBlur() {
        const isInputValid = this.isInputValid();
        if (isInputValid) {
            const telOutput = this.ngTelInput.getNumber();
            this.hasError.emit(isInputValid);
            this.ng2TelOutput.emit(telOutput);
        } else {
            this.hasError.emit(isInputValid);
        }
    }

    isInputValid(): boolean {
        return this.ngTelInput.isValidNumber();
    }

    setCountry(country: string) {
        this.ngTelInput.setCountry(country);
    }

    getUtilsScript(options: SomeOptions) {
        return options.utilsScript || defaultUtilScript;
    }
}
