import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GasMonitorReading, SensorStatusToColor } from '@weavix/models/src/gas/gas';
import { Person } from '@weavix/models/src/person/person';
import * as moment from 'moment';
import { BadgeUpdate } from 'weavix-shared/models/event.model';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { MapService } from 'weavix-shared/services/map.service';

@Component({
    selector: 'app-gas-monitor-readings',
    templateUrl: './gas-monitor-readings.component.html',
    styleUrls: ['./gas-monitor-readings.component.scss'],
})
export class GasMonitorReadingsComponent implements OnChanges {
    @Input() person: Person;

    SensorStatusToColor = SensorStatusToColor;

    private facilityTimezone: string;
    private latestBadge: BadgeUpdate;
    private latestGasMonitorBadge: GasMonitorReading;

    constructor(
        private mapService: MapService,
        private facilityService: FacilityService,
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.person) this.setUp();
    }

    get readingTime(): string { return moment(this.latestGasMonitorBadge.updated).clone().tz(this.facilityTimezone).format('MM/DD/YYYY, hh:mm A'); }
    get hasUpdatedData(): boolean { return !!this.latestGasMonitorBadge && (new Date(this.latestBadge.date)?.getTime() - new Date(this.latestGasMonitorBadge.updated)?.getTime()) < (1000 * 15); }
    get hasReadings(): boolean { return !!this.latestGasMonitorBadge?.readings?.length; }
    get showReadings(): boolean { return this.hasUpdatedData && this.hasReadings; }

    private setUp(): void {
        this.facilityTimezone = this.facilityService.getCurrentFacility()?.timezone || moment.tz.guess();
        const dvrData = this.mapService.getDvrMinuteDataPeople(this.mapService.dvrPlaybackMode ? this.mapService.dvrTimestamp : null);
        this.latestBadge = dvrData?.[this.person.id]?.badge ?? this.person.badge;
        this.latestGasMonitorBadge = this.latestBadge?.gasMonitor;
    }
}