import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input,
    OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { debounce, get } from 'lodash';
import { DateRange, DvrControlsPlaybackState, DvrControlsState, DvrType } from 'weavix-shared/models/dvr.model';
import { Facility } from 'weavix-shared/models/facility.model';
import { FloorPlan } from 'weavix-shared/models/floor-plan.model';
import { Item } from 'weavix-shared/models/item.model';
import { Point } from 'weavix-shared/models/location.model';
import { Person } from 'weavix-shared/models/person.model';
import { Structure } from 'weavix-shared/models/structure.model';
import { TableOptions } from 'weavix-shared/models/table.model';
import { FilterResultType, Geofence, MapFilterCategory, MapFilterResult, MapGeofence, MapLayerToggles, MapMode, MapTableMode, MapWalt, RichMarker as RichMarkerInterface, RichMarkerOptions } from 'weavix-shared/models/weavix-map.model';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { MapFilterService } from 'weavix-shared/services/map-filter.service';
import { MapStateService } from 'weavix-shared/services/map-state.service';
import { MapService } from 'weavix-shared/services/map.service';
import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { ListButton } from 'components/button-list/button-list.component';
import { MapControl } from '../weavix-map/map-control/map-control.component';
import { WeavixMapFilterComponent } from '../weavix-map/weavix-map-filter/weavix-map-filter.component';
import { MapTableModeButtons } from '../weavix-map/weavix-map.model';
import { SimpleMapSearchComponent } from './simple-map-search/simple-map-search.component';
import { ResultType } from 'weavix-shared/models/search.model';

declare const RichMarker: new (opts: RichMarkerOptions) => RichMarkerInterface;

export enum MapDetailViewType {
    GeofenceDetail = 'geofence-detail',
    ItemDetail = 'item-detail',
    PersonDetail = 'person-detail',
    WaltDetail = 'walt-detail',
    NetworkCoverageDetail = 'network-coverage-detail',
}
export interface MapDetailView {
    type: MapDetailViewType;
    entity: Person | Item;
    people?: Person[];
    averages?: {[key:string]: number};
    selectedMetrics?: string[];
    count?: number
}
export interface MapObjectsFilterOptions {
    tags?: string[];
    crafts?: string[];
    itemTypes?: string[];
    folders?: {
        item?: string[];
        person?: string[];
    };
}
export interface MapObjectActions {
    load: boolean;
    update: boolean;
    draw?: boolean;
    edit?: boolean;
    select?: boolean;
}
export interface MapFeatures {
    buttons: {
        zoom: boolean;
        hideShowLabels: boolean;
    };
    actions: {
        panning: boolean;
        mapViewUpdate: boolean;
        clustering: boolean;
    };
    filterOptions?: MapObjectsFilterOptions;
    mapFilter?: {
        showFilterPanel?: boolean;
        floorPlanFilter?: {
            show: boolean,
            multiSelect: boolean
        },
        mapFilterResults?: {[key in MapFilterCategory | string]?: MapFilterResult};
        mapLayerToggleState?: {[key in MapLayerToggles]?: boolean};
        removeFilterPanel?: boolean;
        multiSelect?: boolean
    };
    dvr?: {
        range?: DateRange,
        show: boolean,
        hideSlider?: boolean,
        initialTime?: number;
        type?: DvrType
        removeDateSelection?: boolean;
        disabledDatesUntil?: Date;
        disabledDatesClick?: (date: moment.Moment) => void;
    };
    hasDashboard?: boolean;
    mapControls?: {[key: string]: MapControl | MapControl[]};
    bottomRightIconButton?: {
        id?: string;
        onClick?: () => void;
        isActive?: () => void;
        tooltip?: string;
        color?: string;
        upperIcon?: {
            faIcon: string;
            color: string;
            fontSize: string;
        };
        bottomIcon?: {
            faIcon: string;
            color: string;
            fontSize: string;
        };
    };
    modeButtons?: ListButton[];
    modes?: MapMode[];
    defaultMapMode?: MapMode;
    itemMarkers?: {
        draw: boolean;
    };
    geofences?: {
        draw: boolean;
    };
    geofenceEditMode?: {
        draw: boolean;
    };
    trackingCircles?: {
        draw: boolean;
    };
    tableView?: {
        show: boolean;
        tableOptions: TableOptions;
        tableRows: () => any,
        tableRowAction?: (id?: string) => any
    };
    search?: {
        show: boolean;
        types?: {
            [FilterResultType.Person]?: () => Person[];
            [FilterResultType.Geofence]?: () => Geofence[];
            [FilterResultType.Walt]?: () => MapWalt[];
        }
    };
}
export interface MapView {
    lat: number;
    lng: number;
    bounds: google.maps.LatLngBounds;
    zoom: number;
}
export interface DrawingEvent {
    polygon?: google.maps.Polygon;
    marker?: google.maps.Marker;
    circle?: google.maps.Circle;
}

export enum DrawingType {
    Marker = 'marker',
    Polygon = 'polygon',
    Circle = 'circle',
}

export interface MapOptions {
    mapTypeId?: google.maps.MapTypeId;
    streetViewControl?: boolean;
    tilt?: number;
    fullscreenControl?: boolean;
    disableDefaultUI?: boolean;
    gestureHandling?: google.maps.GestureHandlingOptions;
    backgroundColor?: string;
    hasPoi?: boolean;
    hasTransit?: boolean;
    hasRoad?: boolean;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-simple-map',
    templateUrl: './simple-map.component.html',
    styleUrls: ['./simple-map.component.scss'],
    providers: [MapStateService, MapFilterService],
})

export class SimpleMapComponent implements AfterViewInit, OnInit, OnChanges {
    @Input() person: Person;
    @Input() location: number[];
    @Input() icon?: { value: string, color: string };
    @Input() height: string = '100%';
    @Input() width: string = '100%';

    @Input() features: MapFeatures;
    @Input() mapView: MapView;
    @Input() drawingType: DrawingType | null = null;
    @Input() structures: Structure[];
    @Input() floorPlans: FloorPlan[];
    @Input() mapOptions: MapOptions;
    @Input() tableOptions: TableOptions;
    @Input() detailView: MapDetailView;
    @Input() initialMapTableMode: MapTableMode;
    @Output() mapViewChange: EventEmitter<MapView> = new EventEmitter();
    @Output() mapTableModeChanged: EventEmitter<MapTableMode> = new EventEmitter();
    @Output() drawingEvent: EventEmitter<DrawingEvent> = new EventEmitter();
    @Output() mapInitiated: EventEmitter<google.maps.Map> = new EventEmitter();
    @Output() mapClickPosition: EventEmitter<Point> = new EventEmitter();
    @Output() floorPlanChanged: EventEmitter<{id: string, selected: boolean}> = new EventEmitter();
    @Output() geofenceClicked: EventEmitter<MapGeofence> = new EventEmitter();
    @Output() detailViewClose: EventEmitter<void> = new EventEmitter();

    @ViewChild('map', { static: true }) mapEl: ElementRef;
    @ViewChild('mapPageWrapper', { static: true }) mapPageWrapperEl: ElementRef;
    @ViewChild('floorPlanFilters') floorPlanFilters: WeavixMapFilterComponent;
    @ViewChild('mapFilters') mapFilters: WeavixMapFilterComponent;
    @ViewChild('mapSearch') mapSearch: SimpleMapSearchComponent;

    private gMap: google.maps.Map;
    private drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        polygonOptions: { strokeColor: 'red' },
        circleOptions: { strokeColor: 'red' },
    });
    private drawingEsc: boolean;
    private mapDrawn: boolean;
    private options: google.maps.MapOptions;
    private infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow();
    private defaultMapView: MapView = {
        lat: 36.45323439943358,
        lng: -95.18690655612475,
        bounds: null,
        zoom: 16,
    };

    hasFilters: boolean = false;
    dashboardVisible: boolean = true;
    geofences: Map<string, MapGeofence> = new Map();
    facility: Facility;
    dvrHidden: boolean = true;
    tableModeButtons: ListButton[];
    activeMapTableMode: MapTableMode = MapTableMode.Map;
    mapTableMode = MapTableMode;
    tableRows: any[];
    heatMap: google.maps.visualization.HeatmapLayer;
    heatMapOptions: google.maps.visualization.HeatmapLayerOptions = { data: [] };
    MapDetailViewType = MapDetailViewType;
    activeDetailView: MapDetailView;
    tableSearchQuery: string;
    compactDetailViews = [MapDetailViewType.NetworkCoverageDetail];
    singleMarker: RichMarkerInterface;

    get actionMapControls() {
        return this.features?.mapControls?.actions;
    }

    get hasFloorPlanFilterResults(): boolean {
        return Object.keys(this.floorPlanFilterResults).length > 0;
    }

    get heatMapOn(): boolean {
        return this.features.mapFilter.mapLayerToggleState?.[MapLayerToggles.HeatMap];
    }

    get individualsMapOn(): boolean {
        return this.features.mapFilter.mapLayerToggleState?.[MapLayerToggles.Individuals];
    }

    get mapLoaded(): boolean {
        return this.mapDrawn;
    }

    get mapLat(): number { return this.person?.badge?.location?.[1] ?? this.location?.[1] ?? this.mapView?.lat ?? this.defaultMapView.lat; }
    get mapLng(): number { return this.person?.badge?.location?.[0] ?? this.location?.[0] ?? this.mapView?.lng ?? this.defaultMapView.lng; }

    floorPlanFilterResults?: {[key in MapFilterCategory]?: MapFilterResult} = {};
    floorPlanSortFn = (values: MapFilterResult[]): MapFilterResult[] => {
        const [value] = values;
        return value.type === FilterResultType.Structure ?
        Utils.sortAlphabetical<MapFilterResult>(values, fp => fp.name) :
        Utils.sortByNumber<MapFilterResult>(values, fp => (fp.data as FloorPlan).level);
    };

    constructor(
        public mapService: MapService,
        private changeDetectorRef: ChangeDetectorRef,
        private facilityService: FacilityService,
    ) {}

    async ngOnInit() {
        this.setupInitialMapSettings();
        this.facility = await this.facilityService.getCurrentFacility();
        this.mapService.setupDashboardDisplay(this);
    }

    async ngAfterViewInit() {
        await this.configureMapView();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('drawingType' in changes) this.handleDrawingTypeChange();
        if ('mapView' in changes && this.featurePerm('actions.mapViewUpdate') && this.gMap) this.handleMapViewUpdate();
        if (changes?.structures?.currentValue?.length && changes?.floorPlans?.currentValue?.length) this.setupFloorPlanItems();
        if (changes.detailView?.previousValue !== changes.detailView?.currentValue) this.setActiveDetailView(changes.detailView?.currentValue);
        if (changes.person) this.resetSingleMarker();
    }

    private setupInitialMapSettings(): void {
        this.features = this.features || {
            buttons: {
                zoom: false,
                hideShowLabels: false,
            },
            actions: {
                panning: true,
                mapViewUpdate: false,
                clustering: true,
            },
            filterOptions: {
                tags: null,
                crafts: null,
                itemTypes: null,
                folders: {
                    item: null,
                    person: null,
                },
            },
            mapFilter: {
                showFilterPanel: false,
                mapFilterResults: {},
                mapLayerToggleState: {
                    [MapLayerToggles.Geofences]: false,
                    [MapLayerToggles.Items]: false,
                    [MapLayerToggles.Wilmas]: false,
                },
            },
            dvr: {
                show: true,
            },
            mapControls: {
                recenter: {
                    id: 'recenter',
                    onClick: () => this.handleRecenter(),
                    tooltip: 'shared.map.controls.recenter',
                    icon: 'fas fa-compress-arrows-alt',
                },
                zoomIn: {
                    id: 'zoom-in',
                    onClick: () => this.handleZoomIn(),
                    tooltip: 'shared.map.controls.zoom-in',
                    icon: 'fas fa-plus',
                },
                zoomOut: {
                    id: 'zoom-out',
                    onClick: () => this.handleZoomOut(),
                    tooltip: 'shared.map.controls.zoom-out',
                    icon: 'fas fa-minus',
                },
                dvr: {
                    id: 'dvr',
                    onClick: () => this.toggleDvr(),
                    tooltip: 'shared.map.controls.dvr',
                    icon: 'fas fa-history',
                },
            },
        };
        this.dashboardVisible = this.features.hasDashboard;
        this.mapView = this.mapView || this.defaultMapView;
        this.tableModeButtons = this.features?.tableView ? Object.values(MapTableModeButtons) : null;
    }

    private handleDrawingTypeChange(): void {
        switch (this.drawingType) {
            case DrawingType.Marker:
                if (this.canDropMarker()) this.handleMarker(); break;
            case DrawingType.Polygon:
                if (this.canDrawFences()) this.handlePolygon(); break;
            case DrawingType.Circle:
                if (this.canDrawFences()) this.handleCircle(); break;
            default: this.handleEscape();
        }
    }

    private handleMapViewUpdate(): void {
        this.gMap.setCenter(new google.maps.LatLng(this.mapView.lat, this.mapView.lng));
        this.gMap.setZoom(this.mapView.zoom);
    }

    async resetMap() {
        this.mapDrawn = false;
    }

    public handleZoomIn() {
        this.mapView.zoom = this.gMap.getZoom() + 1;
        this.gMap.setZoom(this.mapView.zoom);
        this.triggerMapViewChange();
    }

    public handleZoomOut() {
        this.mapView.zoom = this.gMap.getZoom() - 1;
        this.gMap.setZoom(this.mapView.zoom);
        this.triggerMapViewChange();
    }

    public handleRecenter() {
        this.gMap.setCenter(this.options.center);
        this.gMap.setZoom(this.options.zoom);
        this.triggerMapViewChange();
    }

    public handleLabels() {
        const styles = this.options.styles;
        const labelStyle = styles.find(s => s.featureType === 'all');
        const visibilityStyler = labelStyle?.stylers?.[0]?.visibility;
        let visibility = true;
        if (visibilityStyler === 'on') visibility = false;
        labelStyle.stylers = [{ visibility: visibility ? 'on' : 'off' }];
        this.gMap.setOptions({ styles });
    }

    public handleEscape() {
        this.resetDrawingManager();
    }

    private handlePolygon() {
        if (!this.canDrawFences()) return;
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        this.drawingEsc = false;
    }

    private handleCircle() {
        if (!this.canDrawFences()) return;
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
        this.drawingEsc = false;
    }

    private handleMarker() {
        if (!this.canDropMarker()) return;
        this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
        this.drawingEsc = false;
    }

    private triggerMapViewChange() {
        this.mapView = this.getCurrentGMapView();
        this.mapViewChange.emit(this.mapView);
    }

    private resetDrawingManager() {
        this.drawingEsc = true;
        this.drawingManager.setDrawingMode(null);
    }

    private setupHeatMap(zoomChange: boolean = false): void {
        if (this.heatMapOn) {
            const radius = 50; // 10 ~= 1m radius
            const zoom = this.gMap.getZoom();
            this.heatMapOptions.map = this.gMap;
            this.heatMapOptions.radius = radius * Math.pow(2, zoom - 18);
            if (!this.heatMap) this.heatMap = new google.maps.visualization.HeatmapLayer(this.heatMapOptions);
            else if (zoomChange) this.heatMap.setOptions({ radius: this.heatMapOptions.radius } as any);
            else this.heatMap.setOptions(this.heatMapOptions);
        }
    }

    private async configureMapView() {
        this.options = this.mapConfig();
        this.gMap = new google.maps.Map(this.mapEl.nativeElement, this.options);
        this.drawingManager.setMap(this.gMap);
        this.gMap.addListener('click', (mapsMouseEvent) => {
            this.mapClickPosition.emit([mapsMouseEvent.latLng.lng(), mapsMouseEvent.latLng.lat()]);
        });

        google.maps.event.addListener(this.gMap, 'zoom_changed', () => {
            this.infoWindow.close();
        });

        this.gMap.setCenter(this.options.center);
        this.gMap.setZoom(this.options.zoom);

        this.gMap.addListener('zoom_changed', debounce(() => this.setupHeatMap(true), 100, { trailing: true }));

        google.maps.event.addListener(this.gMap, 'idle', async () => {
            if (!this.mapDrawn) {
                this.mapDrawn = true;
                this.mapInitiated.emit(this.gMap);
                this.handleTableModeSelection(this.initialMapTableMode);
            }
            if (!this.featurePerm('actions.mapViewUpdate')) return;
            this.triggerMapViewChange();
        });

        google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle: google.maps.Circle) => {
            if (this.drawingEsc === false && this.canDrawFences()) {
                this.drawingEvent.emit({ circle });
            } else {
                circle.setMap(null);
            }
        });

        google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon: google.maps.Polygon) => {
            if (this.drawingEsc === false && this.canDrawFences()) {
                this.drawingEvent.emit({ polygon });
            } else {
                polygon.setMap(null);
            }
        });

        google.maps.event.addListener(this.drawingManager, 'markercomplete', (marker: google.maps.Marker) => {
            if (this.drawingEsc === false && this.canDropMarker()) {
                this.drawingEvent.emit({ marker });
            } else {
                marker.setMap(null);
            }
        });

        if (this.location || this.person) this.setupSingleLocation();
    }

    private resetSingleMarker() {
        if (this.singleMarker) this.singleMarker.setMap(null);
        if (this.location || this.person) this.setupSingleLocation();
        if (this.gMap && !this.gMap.getBounds().contains(this.singleMarker.getPosition())) this.gMap.setCenter(this.singleMarker.getPosition());
    }

    private setupSingleLocation(): void {
        this.singleMarker = new RichMarker({
            id: 'value',
            position: new google.maps.LatLng(this.mapLat, this.mapLng),
            content: this.person ? this.getPersonMarker(this.person) : this.getMapMarker(this.icon.value, this.icon.color),
            removeIfClustered: false,
            type: this.person ? ResultType.Person : ResultType.Alerts,
        });
        this.singleMarker.setMap(this.gMap);
    }

    private getMapMarker(faIcon: string, color: string) {
        return `
        <div class="map-marker">
            <div class="map-marker-icon-container fa-stack">
                <i class="icon-map-marker fa-stack-2x fa-inverse border"></i>
                <i class="icon-map-marker fa-stack-2x background" style="color: ${color}"></i>
                <i class="fas fa-${faIcon} fa-stack-1x fa-inverse icon" style="line-height: inherit"></i>
            </div>
        </div>`;
    }

    private getPersonMarker(person: Person, selected = false) {
        const company = person?.company;
        const color = company?.color ?? '#ffffff';
        const avatar = person?.avatarFile;
        return `
        <div class="map-marker ${selected ? 'selected' : 'not-selected'}" data-id="${person.id}">
            <div class="map-marker-icon-container fa-stack">
                <i class="icon-map-marker fa-stack-2x fa-inverse border"></i>
                <i class="icon-map-marker fa-stack-2x background" style="color: ${color}"></i>
                ${avatar ? `<img class="map-avatar" src="${avatar}"></img>` : this.getInitialsHtml(person.firstName?.charAt(0), person.lastName?.charAt(0))}
            </div>
        </div>`;
    }

    private getInitialsHtml(first: string, last: string) {
        return `
            <div class="map-avatar-initials">
                <span class="text">
                ${first ? first.toUpperCase() : ''}${last ? last.toUpperCase() : ''}
                </span>
            </div>
        `;
    }

    private mapConfig(): google.maps.MapOptions {
        return {
            center: new google.maps.LatLng(this.mapLat, this.mapLng),
            zoom: this.mapView.zoom,
            mapTypeId: this.mapOptions?.mapTypeId ?? google.maps.MapTypeId.HYBRID,
            streetViewControl: this.mapOptions?.streetViewControl ?? false,
            tilt: this.mapOptions?.tilt ?? 0,
            fullscreenControl: this.mapOptions?.fullscreenControl ?? false,
            disableDefaultUI: this.mapOptions?.disableDefaultUI ?? true,
            gestureHandling: this.mapOptions?.gestureHandling ?? (this.featurePerm('actions.panning') ? 'auto' : 'none'),
            backgroundColor: this.mapOptions?.backgroundColor ?? css.colors.MED_GRAY,
            styles: [
                {
                    featureType: 'all',
                    stylers: [{ visibility: 'off' }, { saturation: -100 }],
                },
                {
                    featureType: 'poi',
                    stylers: [{ visibility: this.mapOptions?.hasPoi ? 'on' : 'off' }, { saturation: -100 }],
                },
                {
                    featureType: 'transit',
                    stylers: [{ visibility: this.mapOptions?.hasTransit ? 'on' : 'off' }, { saturation: -100 }],
                },
                {
                    featureType: 'road',
                    stylers: [{ visibility: this.mapOptions?.hasRoad ? 'on' : 'off' }, { saturation: -100 }],
                },
            ],
        };
    }

    private getCurrentGMapView(): MapView {
        const center = this.gMap.getCenter();
        return {
            lat: center.lat(),
            lng: center.lng(),
            bounds: this.gMap.getBounds(),
            zoom: this.gMap.getZoom(),
        };
    }

    private canDropMarker() {
        return this.featurePerm('itemMarkers.draw') || this.featurePerm('personMarkers.draw');
    }

    private canDrawFences() {
        return this.featurePerm('geofences.draw') || this.featurePerm('trackingCircles.draw');
    }

    private featurePerm(path: string) {
        return get(this.features, path, false);
    }

    toggleDashboard() {
        this.mapService.dashboardMode = !this.mapService.dashboardMode;
    }

    public setMapToHomeView(zoom?: number) {
        if (this.mapView.bounds) {
            this.gMap.fitBounds(this.mapView.bounds);
        } else if (this.mapView.lat && this.mapView.lng) {
            this.gMap.setCenter(new google.maps.LatLng(this.mapView.lat, this.mapView.lng));
        }

        if (zoom) {
            this.gMap.setZoom(zoom);
        }
    }

    public setMapBoundsToMarker(markers: google.maps.Marker[]): void {
        if (markers.length > 0) {
            const mapBounds = new google.maps.LatLngBounds();
            markers.forEach(p => {
                mapBounds.extend(p.getPosition());
            });
            this.gMap.fitBounds(mapBounds);
        }
    }

    public clearHeatMapData(): void {
        this.heatMapOptions.map = null;
    }

    public clearHeatMap(): void {
        this.clearHeatMapData();
        this.heatMap?.setMap(null);
    }

    public setHeatMapData(heatMapData: {[key: string]: { location: google.maps.LatLng; weight: number }}): void {
        if (!this.heatMapOn) {
            this.clearHeatMapData();
            return;
        }

        if (!this.heatMap?.getMap()) {
            this.setupHeatMap();
        }

        this.heatMapOptions.data = Object.values(heatMapData);
        this.heatMap?.setData(this.heatMapOptions.data);
    }

    handleDvrPlaybackStateUpdated(state: DvrControlsPlaybackState) {
        this.mapService.setDvrPlaybackState({ inPlaybackMode: state.inPlaybackMode, timestamp: state.timestamp });
    }

    handleDvrControlsStateUpdated(state: DvrControlsState) {
        if (state.firstLoad) return;
        this.mapService.setDvrControlsState(state);
        this.mapService.dvrSliderDrop$.next(state);
    }

    toggleDvr() {
        if (this.features.dvr.show) {
            this.dvrHidden = !this.dvrHidden;
        }
    }

    setupFloorPlanItems(): void {
        const convertItemToFilterResult = (obj: any, cat: MapFilterCategory, type: FilterResultType): MapFilterResult => ({
            name: obj.name,
            key: obj.id,
            data: obj,
            category: cat,
            type,
            selected: false,
            setSelected: (selected) => this.floorPlanChanged.emit({ id: obj.id, selected }),
        });

        this.floorPlanFilterResults = {};
        this.structures.forEach(structure => {
            const floorPlans = this.floorPlans.filter(fp => fp.structureId === structure.id);
            if (!floorPlans || !floorPlans.length) return;
            this.floorPlanFilterResults[structure.id] = {
                name: structure.name,
                key: structure.id,
                data: structure,
                category: MapFilterCategory.Structures,
                type: FilterResultType.Structure,
                selected: false,
                multiselect: false,
                children: floorPlans.reduce((obj, curr) => ({
                    ...obj, [curr.id]: convertItemToFilterResult(curr, MapFilterCategory.FloorPlans, FilterResultType.FloorPlan),
                }), {}),
            };
        });
    }

    handleTableModeSelection(mode: MapTableMode) {
        if (mode === this.activeMapTableMode) return;
        this.mapSearch?.clearSearch();
        this.tableModeButtons?.forEach(m => m.selected = m.keyValue === mode);
        if (mode === MapTableMode.List) this.updateTableRows();
        this.activeMapTableMode = mode;
        this.mapTableModeChanged.emit(mode);
    }

    handleTableSearch(query: string): void {
        this.tableSearchQuery = query;
    }

    updateTableRows(): void {
        this.tableRows = this.features.tableView.tableRows();
    }

    private setActiveDetailView(view: MapDetailView): void {
        this.activeDetailView = view;
        this.changeDetectorRef.detectChanges();
    }

    handleDetailViewClose(): void {
        this.setActiveDetailView(null);
        this.detailViewClose.emit();
    }

    handleTableRowClick(event): void {
        this.features.tableView.tableRowAction(event.key);
    }

    resetFloorPlanFilters(): void {
        this.floorPlanFilters?.handleClearFilters();
    }

    clearFilterCategory(parent: MapFilterResult): void {
        this.mapFilters?.clearAllSelections(parent);
    }

    clearAllFilterSelections(): void {
        this.mapFilters?.handleClearFilters();
    }
}
