
<div class="flexColumn jcC">
    <app-loading [loading]="isLoading" theme="dark"></app-loading>

    <div class="content">
        <div class="avatar-container" *ngIf="avatarInput">
            <app-avatar [avatarInput]="avatarInput"></app-avatar>
        </div>

        <div class="video-container"  *ngIf="!avatarInput">
            <video #video class="video" [hidden]="!stream"></video>
            <canvas #canvas [width]="videoWidth" [height]="videoHeight" class="hidden"></canvas>
        </div>
            
        <div class="buttons-container">
            <div class="flex1">
                <button class="p0 fs20p" (click)="file.value = null; file.click();" matTooltip="{{ translateService.getImmediate('avatar.upload-file') }}">
                    <app-icon faIcon="fas fa-upload"></app-icon>
                </button>
            </div>
            <button class="btn blue-lt circle-icon fs20p" (click)="handleCaptureClick()" [disabled]="captureDisabled">
                <app-icon faIcon="fas fa-camera"></app-icon>
            </button>
            <div class="flex1"></div>
        </div>
    </div>

    <div class="flexRow jcC mT20" *ngIf="videoDeviceItems.length > 1">
        <app-dropdown class="w400"
            [dropdownInput]="videoDeviceItems"
            [(ngModel)]="selectedDeviceId"
            floatedLabel="meeting.devices"
            (dropdownOutput)="handleDeviceChange($event)"
        ></app-dropdown>
    </div>

    <input type="file"
        id="avatar-image-input"
        [hidden]="true"
        (change)="handleFileSelect($event)"
        accept="image/jpeg,image/png"
        #file
    />
</div>
