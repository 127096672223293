import { AbstractControl } from '@angular/forms';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { autorun } from 'mobx';

function safeSubscriptionDestroy() {
    if (this['destroyed-x$'] && !this['destroyed-x$'].closed) {
        this['destroyed-x$'].next(true);
    }
    if (this.ngOnDestroyLegacy) {
        this.ngOnDestroyLegacy();
    }
}

export function AutoUnsubscribe(c?) {
    const fn = function(constructor) {
        if (constructor.prototype.ngOnDestroy !== safeSubscriptionDestroy) {
            constructor.prototype.ngOnDestroyLegacy = constructor.prototype.ngOnDestroy;
            constructor.prototype.ngOnDestroy = safeSubscriptionDestroy;
        }
    };
    if (c) fn(c);
    else return fn;
}

export interface RelativeDate {
    type: 'add' | 'subtract';
    unit: 'year' | 'month' | 'week' | 'day';
    amount: number;
    fixType: 'start' | 'end';
    fixUnit: 'year' | 'month' | 'week';
    dayOfWeek: number; // 0 = sunday
}

export function getRelativeDate(type: RelativeDate, timezone: string, now?: number) {
    let val = moment.unix((now || new Date().getTime()) / 1000).tz(timezone);
    if (type) {
        if (type.type === 'add') val = val.add(type.amount, type.unit);
        if (type.type === 'subtract') val = val.subtract(type.amount, type.unit);
        if (type.fixType === 'start') {
            if (type.fixUnit === 'week') {
                let diff = type.dayOfWeek - val.day();
                if (diff > 0) diff -= 7;
                val = val.add(diff, 'day');
            } else val = val.startOf(type.fixUnit);
        }
        if (type.fixType === 'end') {
            if (type.fixUnit === 'week') {
                let diff = type.dayOfWeek - val.day();
                if (diff < 0) diff += 7;
                val = val.add(diff, 'day');
            } else val = val.endOf(type.fixUnit);
        }
    }
    return val.startOf('day').toDate();
}

export function getBatteryIcon(battery: number) {
    if (battery === 0) {
        return 'fas fa-battery-empty';
    } else if (battery <= 25) {
        return 'fas fa-battery-quarter';
    } else if (battery <= 50) {
        return 'fas fa-battery-half';
    } else if (battery <= 75) {
        return 'fas fa-battery-three-quarters';
    } else {
        return 'fas fa-battery-full';
    }
}

export class Utils {

    static SELECT_LIMIT: number = 50;
    static DEFAULT_TIMEZONE = 'America/Chicago';

    static ACCEPTABLE_CSV = '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
    static ACCEPTABLE_VIDEO = '.mp4';
    static EMAIL_REGEX = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

    static accountId: string;

    static formatDate(date: Date, format: string): string {
        if (!date || !format || !date.getTime || isNaN(date.getTime())) return;
        return moment(date).clone().format(format);
    }

    static toUtcDate(date: Date | string) {
        if (!date) return null;
        if (typeof date === 'string') date = new Date(date);
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    }

    static fromUtcDate(date: Date | string, tzAdjust: number = 1) {
        if (!date) return null;
        if (typeof date === 'string') {
            date = new Date(date);
            if (isNaN(date.getTime())) return null;
        }
        return new Date(date.getTime() - tzAdjust * date.getTimezoneOffset() * 60000);
    }

    static getStartOfTimeUnit(date: Date | string, unitOfTime: moment.unitOfTime.Base): number {
        return moment(date).clone().startOf(unitOfTime).valueOf();
    }

    static getEndOfTimeUnit(date: Date | string, unitOfTime: moment.unitOfTime.Base): number {
        return moment(date).clone().endOf(unitOfTime).valueOf();
    }

    static safeSubscribe(t: any): Observable<boolean>;
    static safeSubscribe<T>(t: any, o: Observable<T>): Observable<T>;
    static safeSubscribe<T>(t: any, o?: Observable<T>) {
        if (!t) return o;

        t['destroyed-x$'] = t['destroyed-x$'] || new ReplaySubject(1);

        const cmp = t.constructor['ɵcmp'];
        if (!cmp) throw new Error(`This is not a component ${t.constructor.name}, what the hell are you thinking?`);
        if (t.constructor.prototype.ngOnDestroy !== safeSubscriptionDestroy) {
            throw new Error(`Component requires @AutoUnsubscribe ${t.constructor.name}`);
        }
        return o ? o.pipe(takeUntil(t['destroyed-x$'])) : t['destroyed-x$'];
    }

    static safeDispose(t: any, fn: () => any) {
        const obs = Utils.safeSubscribe(t);
        obs.subscribe(fn);
        return fn;
    }

    static safeAutorun(t: any, fn: () => void, delay = 250) {
        let first = true;
        // Custom scheduler to run the function immediately and then delay thereafter
        const scheduler = run => {
            if (first) {
                first = false;
                run();
            } else setTimeout(run, delay);
        };
        const fnWrapper = () => {
            const fnResult = fn();
            if ((fnResult as any)?.then) {
                // autorun only detects changes in synchronous callbacks
                throw new Error('Do not pass async functions to safeAutorun! It doesn\'t work!');
            }
        };
        return this.safeDispose(t, autorun(fnWrapper, { scheduler }));
    }

    static sortByDate(sort: any[], dateProp: string, asc: boolean = true): any[] {
        if (asc) return sort.sort((a, b) => new Date(a[dateProp]).getTime() - new Date(b[dateProp]).getTime());
        else return sort.sort((a, b) => new Date(b[dateProp]).getTime() - new Date(a[dateProp]).getTime());
    }

    static sortAlphabetical<T>(sort: T[], valueSort?: string | ((v: T) => string)) {
        return sort.sort(this.alphabeticalSorter(valueSort));
    }

    static alphabeticalSorter<T>(valueSort?: string | ((v: T) => string)) {
        const getValue = typeof valueSort === 'function'
            ? (x: T) => valueSort(x)
            : (x: T) => _.get(x, valueSort);
        return (a: T, b: T) => {
            const aVal: string = getValue(a)?.toString() ?? a?.toString() ?? '';
            const bVal: string = getValue(b)?.toString() ?? b?.toString() ?? '';
            return aVal.localeCompare(bVal, undefined, { sensitivity: 'base' });
        };
    }

    static sortByNumber<T>(sort: any[], valueSort?: string | ((v: T) => number), asc: boolean = true): any[] {
        const getValue = typeof valueSort === 'function'
                            ? (x: T) => Number(valueSort(x))
                            : (x: T) => Number(_.get(x, valueSort));
        if (asc) return sort.sort((a, b) => getValue(a) - getValue(b));
        else return sort.sort((a, b) => getValue(b) - getValue(a));
    }

    static awaitable<T>(obs: Observable<T>): Observable<T> & Promise<T> {
        const ret: Observable<any> & Promise<any> = obs as any;

        if (ret) {
            ret.then = async (onfulfilled?: ((value: any) => any | PromiseLike<any>) | undefined | null,
                              onrejected?: ((reason: any) => any | PromiseLike<any>) | undefined | null): Promise<any> => {
                return new Promise((resolve, reject) => {
                    obs.subscribe((result) => {
                        if (onfulfilled) {
                            try {
                                resolve(onfulfilled(result));
                            } catch (e) {
                                reject(e);
                            }
                        } else {
                            resolve(result);
                        }
                    }, (err: unknown) => {
                        if (onrejected) {
                            try {
                                resolve(onrejected(err));
                            } catch (e) {
                                reject(e);
                            }
                        } else {
                            reject(err);
                        }
                    });
                });
            };
            ret.catch = (onrejected) => {
                return ret.then(null, onrejected);
            };
        }
        return ret;
    }

    static getAccountId(accounts?: Array<{ id?: string; name?: string; }>): string {
        if (!accounts) return this.accountId;

        const parts = location.href.split('/');
        if (parts[3] === 'a') {
            this.accountId = accounts.find(x => this.getAccountUrl(x.name) === parts[4])?.id;
            return this.accountId;
        }
        return null;
    }

    static getAccountUrl(name: string) {
        return name.replace(/([^a-zA-Z0-9]+)/g, '-').replace(/^-/, '').replace(/-$/, '').toLowerCase();
    }

    static async readFile(event: any): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            if (event.target.files && event.target.files.length) {
                const [file] = event.target.files;
                reader.readAsDataURL(file);

                reader.onload = () => resolve(reader.result);
                reader.onerror = (e) => reject(e);
            }
        });
    }

    static notEmpty(v: any) {
        return v != null && v !== '';
    }

    static isGuid(val: string): boolean {
        return val && !!val.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i);
    }

    static hasGuid(val: string): boolean {
        return val && !!val.match(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i);
    }

    /**
     * Currently used for calculating if a person should be on/off map when checked into a cs (radius in meters)
     */
    static isWithinRadiusOfLocation(location: google.maps.LatLng, target: google.maps.LatLng, radius: number = 30): boolean {
        return radius > google.maps.geometry.spherical.computeDistanceBetween(location, target);
    }

    static toMap<T, U>(items: T[], getKey: (item: T) => any = x => x['id'], getValue: (item: T) => U = x => <U><unknown>x) {
        return items?.reduce((agg, i) => agg.set(getKey(i), getValue(i)), new Map<string, U>()) ?? new Map<string, U>();
    }

    static toObjectMap<T, U>(items: T[], getKey: (item: T) => any = x => x['id'], getValue: (item: T) => U = x => <U><unknown>x): { [key: string]: U } {
        return items?.reduce((agg, i) => (agg[getKey(i)] = getValue(i), agg), {}) ?? {};
    }

    // eslint-disable-next-line complexity
    static durationConversion(input: number, from: 'day' | 'hour' | 'min' | 'sec' | 'ms', to: 'day' | 'hour' | 'min' | 'sec' | 'ms'): number {
        if (!input) return null;
        switch (from) {
            case 'day':
                switch (to) {
                    case 'day':
                        return input;
                    case 'hour':
                        return input * 24;
                    case 'min':
                        return input * 1440;
                    case 'sec':
                        return input * 86400;
                    case 'ms':
                        return input * 86400000;
                    default:
                        return input;
                }
            case 'hour':
                switch (to) {
                    case 'day':
                        return input * 24;
                    case 'hour':
                        return input;
                    case 'min':
                        return input * 60;
                    case 'sec':
                        return input * 3600;
                    case 'ms':
                        return input * 3600000;
                    default:
                        return input;
                }
            case 'min':
                switch (to) {
                    case 'day':
                        return input / 1440;
                    case 'hour':
                        return input / 60;
                    case 'min':
                        return input;
                    case 'sec':
                        return input * 60;
                    case 'ms':
                        return input * 60000;
                    default:
                        return input;
                }
            case 'sec':
                switch (to) {
                    case 'day':
                        return input / 86400;
                    case 'hour':
                        return input / 3600;
                    case 'min':
                        return input / 60;
                    case 'sec':
                        return input;
                    case 'ms':
                        return input * 1000;
                    default:
                        return input;
                }
            case 'ms':
                switch (to) {
                    case 'day':
                        return input / 86400000;
                    case 'hour':
                        return input / 3600000;
                    case 'min':
                        return input / 60000;
                    case 'sec':
                        return input / 1000;
                    case 'ms':
                        return input;
                    default:
                        return input;
                }
        }
    }

    static msToFormattedDuration(duration: number, timerFormat: boolean = false): string {
        let seconds = Math.floor(duration / 1000);
        let minute = Math.floor(seconds / 60);
        seconds = seconds % 60;
        let hour = Math.floor(minute / 60);
        minute = minute % 60;
        const day = Math.floor(hour / 24);
        hour = hour % 24;

        let val = ``;
        if (!timerFormat) {
            val = day || hour || minute || seconds ? `` : '0';
            if (day) val += `${day} day(s) `;
            if (hour) val += `${hour} hr(s) `;
            if (minute) val += `${minute} min `;
            if (seconds) val += `${seconds} sec `;
        } else {
            const dblDigitValue = (value: number) => value < 10 ? `0${value}` : value;
            val += `${dblDigitValue(hour)}:${dblDigitValue(minute)}:${dblDigitValue(seconds)} `;
        }

        return val;
    }

    static distanceConversion(distance: number, from: string, to: string) {
        if (from === to) return distance;

        switch (from) {
            case 'm':
                switch (to) {
                    case 'ft':
                        return Math.round(distance * 3.28084);
                    default:
                        throw new Error(to);
                }
            case 'ft':
                switch (to) {
                    case 'm':
                        return distance / 3.28084;
                    default:
                        throw new Error(to);
                }
            default:
                throw new Error(from);
        }
    }

    static toProperCase(name: string) {
        return name?.replace(/\w\S*/g, (text: string) => text?.charAt(0).toUpperCase() + text?.substr(1).toLowerCase());
    }

    static convertTimeToLocaleString(value: number, locale: string, tz: string): string {
        const options: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: '2-digit',
        };
        if (tz) {
            options.timeZone = tz;
            options.timeZoneName = 'short';
        }
        return `${new Date(value).toLocaleTimeString(locale, options)}`;
    }

    static writeCsv(lists: string[][]) {
        return lists.map(list => {
            return list.map(entry => `"${entry.toString().replace(/"/g, '""')}"`).join(',');
        }).join('\r\n');
    }

    static saveAsCsv(csvArray, fileName: string): void {
        const name = !fileName || fileName === '' ? 'export' : fileName;
        const blob = new Blob([csvArray], { type: 'text/csv' } );
        FileSaver.saveAs(blob, `${name}.csv`);
    }

    static stringToUTF8Bytes(str: string): Uint8Array {
        return new TextEncoder().encode(str);
    }

    static bytesToHex(bytes: Uint8Array): string {
        return Array.from(
            bytes,
            byte => this.decimalToHex(byte),
        ).join('');
    }

    static decimalToHex(decimal: number): string {
        return decimal.toString(16).padStart(2, '0');
    }

    static generatePin() {
        return Math.floor((Math.random() * 10000) + 1).toString().padStart(4, '0');
    }

    static capitalizeFirstLetter(str: string): string {
        str = str.toLocaleLowerCase();
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

}

export function isChrome() {
    const isChromium = !!window['chrome'];
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isIEedge = winNav.userAgent.indexOf('Edg') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');

    return isIOSChrome || (isChromium && vendorName === 'Google Inc.' && !isIEedge);
}

export function isEdge() {
    const isIE = /*@cc_on!@*/false || !!document['documentMode'];
    return !isIE && !!window['StyleMedia'];
}

export function isFirefox() {
    return typeof window['InstallTrigger'] !== 'undefined';
}

export function getMeetingWindowFeatures() {
    const width = window.screen.availWidth;
    const height = window.screen.availHeight;
    return getPopoutWindowFeatures(width, height);
}

export function getRadioWindowFeatures() {
    return getPopoutWindowFeatures(1500, 820);
}

function getPopoutWindowFeatures(width: number, height: number) {
    const left = window.screenLeft;
    const top = window.top;
    return `top=${top},left=${left},width=${width},height=${height}`;
}

export const MEETING_WINDOW_TARGET = 'Weavix Meeting';
export const RADIO_WINDOW_TARGET = 'Weavix Radio';

const PSEUDO_STORAGE = {};

export function setItem(key: string, value: string) {
    PSEUDO_STORAGE[key] = value;
    try { localStorage.setItem(key, value); } catch (e) { console.error('utils: failed to setItem', key, e); }
}

export function getItem(key: string) {
    try { return PSEUDO_STORAGE[key] ?? localStorage.getItem(key); } catch (e) { return null; }
}

export function isFullscreen() {
    return !!document.fullscreenElement;
}

export function lookupLocalStorage(filterStrings: string[], func: (key: string) => void) {
    let index = localStorage.length - 1;
    while (index >= 0) {
        const storageKey = localStorage.key(index);
        if (filterStrings.some(x => storageKey?.includes(x))) {
            func(storageKey);
        }
        index -= 1;
    }
}

export function fullscreen() {
    if (!isFullscreen()) {
        const doc = document.documentElement as any;
        if (doc.requestFullscreen) {
            doc.requestFullscreen();
        } else if (doc.webkitRequestFullscreen) { /* Safari */
            doc.webkitRequestFullscreen();
        } else if (doc.msRequestFullscreen) { /* IE11 */
            doc.msRequestFullscreen();
        }
    } else {
        const doc = document as any;
        if (doc.exitFullscreen) {
            doc.exitFullscreen();
        } else if (doc.webkitExitFullscreen) { /* Safari */
            doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) { /* IE11 */
            doc.msExitFullscreen();
        }
    }
}

export function roundedPercentLabel(value: number, decimalPlace: number = 1): string {
    return `${Math.round(value * decimalPlace) / decimalPlace}%`;
}

export function onlyAllowDigits(formControl: AbstractControl) {
    formControl.setValue(formControl.value?.replace(/[^0-9]/g, ''));
}

type MarkFunctionPropertyNames<T> = {
    // eslint-disable-next-line @typescript-eslint/ban-types -- Function is needed to match implemented class definition.
    [Key in keyof T]: T[Key] extends Function | Subject<any> ? never : Key;
};
type ExcludeFunctionPropertyNames<T extends object> = MarkFunctionPropertyNames<T>[keyof T];
type ExcludeFunctions<T extends object> = Pick<T, ExcludeFunctionPropertyNames<T>>;
export type SimpleChangesTyped<TComponent extends object, TProps = ExcludeFunctions<TComponent>> = {
    [Key in keyof TProps]: {
        previousValue: TProps[Key];
        currentValue: TProps[Key];
        firstChange: boolean;
        isFirstChange(): boolean;
    }
};

export function htmlToDocumentFragment(htmlString): DocumentFragment {
    const tempDiv = document.createElement('DIV');
    tempDiv.innerHTML = htmlString;
    if (tempDiv.childNodes.length === 1) {
        return tempDiv.removeChild(tempDiv.firstChild) as any;
    } else {
        const fragment = document.createDocumentFragment();
        while (tempDiv.firstChild) {
            fragment.appendChild(tempDiv.firstChild);
        }

        return fragment;
    }
}

export function getMeetingWindowTarget() {
    return 'Weavix Crews';
}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
