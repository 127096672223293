import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ThemeService } from 'weavix-shared/services/themeService';
import { FilterResultType, TableFilterCategory, TableFilterResult } from 'weavix-shared/models/table.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { TableService } from '../table.service';
import { DateRange } from 'weavix-shared/models/dvr.model';

export interface TableFilterSearchConfig {
    enabled: boolean;
    category: TableFilterCategory;
    placeholderKey?: string;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-table-filter',
    templateUrl: './table-filter.component.html',
    styleUrls: ['./table-filter.component.scss', '../table-filter-row/table-filter-row.component.scss'],
})

export class TableFilterComponent implements OnChanges, OnInit {
    @Input() headerTitle: string;
    @Input() skipSortFilters: boolean = false;
    @Input() filterResults: {[key: string]: TableFilterResult} = {};
    @Input() multiCategorySelect = false;
    @Input() hasDateRangeFilter = false;
    @Input() activeDateRange: DateRange;

    selectedDateRange: DateRange;
    private dateRangeFilter: TableFilterResult = {
        name: 'date',
        key: 'date',
        category: TableFilterCategory.Date,
        type: FilterResultType.Date,
        selected: true,
    };

    loading: boolean = false;
    lightTheme: boolean;

    get titleKey(): string { return this.headerTitle ?? 'generics.filters'; }
    get filterResultsToDisplay() {
        let filters = Object.values(this.filterResults);
        if (!this.skipSortFilters) filters = filters.sort((a, b) => a.name < b.name ? -1 : 1);
        // filtered options with no children should always be at the bottom of the list
        return filters.filter(f => f.children).concat(filters.filter(f => !f.children));
    }

    constructor(
        public tableService: TableService,
    ) {}

    getChildren(res: TableFilterResult) {
        const children = Object.values(res.children ?? {});
        if (res.skipSortChildren) return children;
        return children.sort((a, b) => a.name < b.name ? -1 : 1);
    }

    ngOnInit() {
        this.loading = true;
        this.lightTheme = ThemeService.getLightTheme();
        this.loading = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.loading = true;
        if (changes.filterResults) {
            this.checkForEmptyFilters();
        }
        if (changes.activeDateRange) {
            this.selectedDateRange = this.activeDateRange;
        }
        this.loading = false;
    }

    handleDateRangeFilterClick(): void {
        if (!this.dateRangeFilter) return;
        if (this.dateRangeFilter.disabled && this.dateRangeFilter.disabled()) return;
        else if (this.dateRangeFilter?.customClickAction) this.dateRangeFilter.customClickAction();
        else {
            this.dateRangeFilter.selected = !this.dateRangeFilter.selected;
            this.dateRangeFilter.setSelected?.(this.dateRangeFilter.selected, true, true);
        }
    }

    handleDateRangeFilterChange(dateRange: DateRange): void {
        this.tableService.selectedDateFilters[TableFilterCategory.Date] = dateRange;
        this.tableService.filterUpdate$.next({ resetFilters: false });
    }

    handleClearFilters() {
        this.selectedDateRange = this.activeDateRange;
        if (this.selectedDateRange) {
            this.dateRangeFilter.selected = false;
            delete this.tableService.selectedDateFilters[TableFilterCategory.Date];
            this.tableService.filterUpdate$.next({ resetFilters: false});
        }
        this.deselectCategory({ children: this.filterResults } as any);
    }

    handleRowClick(parent: TableFilterResult, cat: TableFilterResult, click: boolean, depth: number): void {
        if (!cat) return;

        if (!this.multiCategorySelect) this.deselectCategory(parent, cat);
        if (depth > 0 && !parent.multiselect) this.deselectCategory(parent, cat);
        if (cat?.selected || click) this.deselectCategory(cat);
        cat.selected = click ? true : !cat.selected;
        cat.setSelected?.(cat.selected, true, click);
    }

    deselectCategory(cat: TableFilterResult, ignore?): void {
        if (cat.children) {
            Object.values(cat.children).forEach(subCat => {
                if (subCat === ignore || !subCat.selected) return;
                subCat.selected = false;
                subCat.setSelected?.(subCat.selected);
                subCat.hidden = false;
                this.deselectCategory(subCat);
            });
        }
    }

    checkForEmptyFilters() {
        let allEmpty: boolean = true;
        for (const val of Object.values(this.filterResults)) {
            if (Object.values(val.children).length) {
                allEmpty = false;
                break;
            }
        }

        if (allEmpty) return;
    }

}
